/* global Component*/
class classificationFilterComponent extends Component {

    static name() {
        return "classificationFilterComponent";
    }

    static componentName() {
        return "classificationFilterComponent";
    }

    getProps() {
        return ['filterField','open_by_default'];
    }

    data() {
        return {
            display:false,
            isMobile: $(window).width() <= 560,
            renderView:false,
            collections:[],
        };
    }

    getWatch() {
        return {
            '$route': 'generateFilter',
            '$store.state.itemTimeFlag':'generateFilter',
            '$store.state.itemUpdate': 'calculateResults'
        };
    }

    mounted() {
        return async function () {
            let self = this;
            this.fetchData = this.fetchData.bind(this);
            this.subscribeEvent("category-select",this.fetchData);
            this.display = this.open_by_default;
            $(window).resize(function () {
                self.isMobile = $(window).width() <= 560;
                if(self.isMobile)
                    self.display = false;
            });
        };
    }

    getMethods() {
        return {
            loadCategory:this.loadCategory,
            generateFilter:this.generateFilter,
            resetCountFilters:this.resetCountFilters,
            calculateResults:this.calculateResults,
            launchFilter:this.launchFilter,
            getItemValue:this.getItemValue,
            getCategoryName:this.getCategoryName,
            fetchData: this.fetchData,
        };
    }

    async loadCategory(categoryCode) {
        let url = `/ecommerce/api/getCategoryFilter?categoryCode=${categoryCode}`;
        let resp = await fetch(url);
        return await resp.json();
    }

    async generateFilter(){
        let collection = new Map();
        let items = this.$store.state.items_store.values();
        //let classificationApply = this.filterField.FilterCollection;
        //let ClassificationList = await this.loadCategory(classificationApply)
        //console.log('Classification Tree',ClassificationList.categoryList)
        let globalCategory = this.$store.getters.getGlobalCategories;
        for(let item of items) {
            let itemsClassification = item.Classification;
            if(!itemsClassification)
                continue;
            for(let itemClass of itemsClassification.split(',')){
                let valueSplit = this.$store.getters.getCategoryDataByCode(itemClass);
                if(!valueSplit || globalCategory.indexOf(valueSplit.code) != -1) {
                    continue;
                }
                //console.log('ClassObj',valueSplit);
                if(valueSplit.level > 0 && valueSplit.level < 4 && !collection.has(valueSplit.name)) {
                    let objectFilter = {};
                    objectFilter.name = `classification-filter-${valueSplit.name}-${valueSplit.code}`;
                    objectFilter.type = 'classificationFilter';
                    objectFilter.label = valueSplit.name;
                    objectFilter.value = valueSplit.name;
                    objectFilter.code = valueSplit.code;
                    objectFilter.filterField = "Classification";
                    objectFilter.itemCount = 1;
                    objectFilter.hiddens = new Map();
                    objectFilter.condition = `x.ClassificationPath['${valueSplit.code}']!=undefined`;
                    //objectFilter.condition = `x.Classification.split(',').indexOf(\`${valueSplit.code}\`)!=-1`;
                    objectFilter.result = true;
                    objectFilter.active = false;
                    collection.set(valueSplit.name,objectFilter);
                }
            }
        }
        this.collections = Array.from(collection.values());
        //console.log('Class in Item', this.collections);
        this.calculateResults();
    }

    resetCountFilters(){
        for(let filter of this.collections) {
            filter.itemCount = 0;
        }
    }

    calculateResults(){
        let items =  this.$store.getters.getItemsVisibles;  //this.$store.getters.getItems
        this.resetCountFilters();
        let global = 0;
        for(let x of items.filter((e)=>e.visible)) {
            for (let interval of this.collections) {
                let isPresent = RegExp(interval.code, 'gi');
                let isVisible = isPresent.test(x[interval.filterField]);
                if (isVisible) {
                    interval.itemCount = interval.itemCount + 1;
                    interval.active = this.$store.state.filters.indexOf(interval.name) !== -1;
                    global = global + 1;
                }
            }
        }
        if(this.open_by_default) {
            this.display = this.collections.filter((e)=>e.itemCount > 0).length > 0;
        }
    }

    launchFilter(filters){
        filters.active = true & filters.active;
        this.$store.dispatch('addFilter',filters);
    }

    getItemValue(item,fieldName){
        if(item.fields)
            item = item.fields;
        return item[fieldName];
    }

    getCategoryName(categoryCode){
        try {
            let catTree = this.$store.getters.getCategoryDataByCode(categoryCode);
            if(catTree) {
                //console.log(catTree)
                return catTree.name;
            }
            return null;
        }
        catch(err){
            //console.log('Error in categoryFilters',categoryCode,catTree,err);
            return categoryCode;
        }
    }

    fetchData() {
        this.renderView = !this.renderView;
        this.calculateResults();
    }

    getTemplate() {
        return `<div class="filter classificationFilter" >
                   <div>
                        <p class="filter-title" @click="display = !display">
                            {{tr(filterField.ItemField)}}
                            <span v-show="display" class="right"><i class="icon fas fa-minus"></i></span>
                            <span v-show="!display" class="right"><i class="icon fas fa-plus"></i></span>
                        </p>
                    </div>
                    <section class="filter-options" :class="{'visible-filter': display}" @click="display = !isMobile">
                        <ul>
                          <template v-for="filterClass of collections">
                                <li :key="'li'+filterClass.name" v-if="filterClass.itemCount>0">
                                    <div class="custom-control custom-checkbox">
                                      <input :key="'input'+filterClass.name" class="custom-control-input" type="checkbox" v-model="filterClass.active" :id="filterClass.name" :name="filterClass.name"  @click="launchFilter(filterClass)"/>
                                      <label :for="filterClass.name" class="custom-control-label" >{{filterClass.label}} ({{filterClass.itemCount}})</label>
                                    </div>
                                </li>
                          </template>
                        </ul>
                    </section>
               </div>`;
    }
}

classificationFilterComponent.registerComponent();